<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

    <v-dialog
        v-model="dialog"
        persistent
        :max-width="900"
    >
      <v-card>
        <v-card-title class="text-h5" id="title">
          <v-icon class="pe-3" color="secondary" size="40">
            mdi-post
          </v-icon>
          Eintrag erstellen
        </v-card-title>
        <v-card-text>
          <v-form ref="formular">
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="ps-md-6">
                  <v-text-field
                      v-model="newTitle"
                      label="Titel"
                      :readonly="false"
                  >
                  </v-text-field>
                  <v-text-field
                      v-model="newDescription"
                      label="Beschreibung"
                      :readonly="false"
                  >
                  </v-text-field>
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"

                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="newStart"
                          label="Startzeit"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                        v-model="newStart"
                        label="Startzeit"
                        :data="newStart"
                        @input="menu = false"
                        @change="menu = false"
                        format="24hr"
                        max-width="700"
                    ></v-time-picker>
                  </v-menu>
                  <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"

                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="newEnd"
                          label="Startzeit"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                        v-model="newEnd"
                        label="Startzeit"
                        :data="newEnd"
                        @input="menu2 = false"
                        @change="menu2 = false"
                        format="24hr"
                        max-width="700"
                    ></v-time-picker>
                  </v-menu>

                </v-col>
                <v-col cols="12" md="6" class="ps-md-6">
                  <v-autocomplete
                      v-for="(vendor, index) of vendorCount"
                      :data="newVendors[index]"
                      label="Dienstleister"
                      :items="vendors"
                      v-model="newVendors[index]"
                      :key="index"
                  ></v-autocomplete>
                  <v-btn @click="vendorCount.push(1)">Weiterer Denstleister</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="createTimePlanEntry"
          >
            Erstellen
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="resetForm"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "CreateTimePlanEntry",
  components: {
    Message,
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('vendor', {
      vendors: 'vendorsForSelection',
    }),
  },
  data() {
    return {
      message: msgObj(),
      menu: false,
      menu2: false,
      newTitle: "",
      newDescription: "",
      newStart: "",
      newEnd: "",
      newVendors: {},
      vendorCount: [1]
    }
  },
  props: {
    dialog: Boolean,
    timePlanId: String
  },
  mounted() {
    this.$store.dispatch('vendor/getVendorsForSelection', {
      uid: this.user.id
    }).catch((err) => {
      this.message = error(err)
    })
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    resetForm(){
      this.newTitle = ""
      this.newStart = ""
      this.newDescription = ""
      this.newEnd = ""
      this.newVendors = {}
      this.vendorCount = [1]
      this.$emit('hideDialog')

    },
    createTimePlanEntry() {
      this.$store.dispatch('timePlan/createTimePlanEntry', {
        uid: this.user.id,
        timePlanId: this.timePlanId,
        newTitle: this.newTitle,
        newVendors: this.newVendors,
        newStart: this.newStart,
        newEnd: this.newEnd,
        newDescription: this.newDescription,
      }).then(() => {
        this.$emit('created')
      }).catch((err) => {
        this.message = error(err)
      })
        this.hideDialog()
        this.message = success('Eintrag wurde erfolgreich angelegt.')
    },
  },
}
</script>
